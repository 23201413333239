var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:_vm.$style.bonusProgram},[_c('div',{class:_vm.$style.wrapper},[_c('adw-tabs',{class:_vm.$style.tabs,attrs:{"activeTab":_vm.activeBonusProgramsTab,"tabs":_vm.bonusProgramTabs,"color":"gold"},on:{"change":_vm.handleChangeTab}}),(!_vm.isRemoteManager)?_c('div',{class:_vm.$style.buttons},[_c('adw-button',{attrs:{"color":"primary","link":_vm.getRoute({
            route: _vm.$options.ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.CREATE,
          })}},[_vm._v(" Создать бонусную программу ")])],1):_vm._e()],1),_c('el-table',{attrs:{"data":_vm.bonusPrograms,"stripe":""}},[_c('el-table-column',{attrs:{"prop":"name","label":"Название"}}),_c('el-table-column',{attrs:{"prop":"description","label":"Описание"}}),_c('el-table-column',{attrs:{"prop":"status","label":"Статус"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{class:[
            {
              [_vm.$style.active]:
                scope.row.status === _vm.$options.BONUS_PROGRAM_STATUSES.ACTIVE,
            },
            {
              [_vm.$style.archive]:
                scope.row.status === _vm.$options.BONUS_PROGRAM_STATUSES.ARCHIVED,
            },
          ]},[_vm._v(_vm._s(_vm.getDisplayStatus(scope.row.status)))])]}}])}),_c('el-table-column',{attrs:{"prop":"startsAt","label":"Дата начала"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.formatDate(scope.row.startsAt))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"endsAt","label":"Дата окончания"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.formatDate(scope.row.endsAt))+" ")]}}])}),_c('el-table-column',{attrs:{"width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.status !== _vm.$options.BONUS_PROGRAM_STATUSES.ARCHIVED)?_c('router-link',{class:_vm.$style.link,attrs:{"to":_vm.getRoute({
              route:
                _vm.$options.ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.EXCLUDE_DEALERS
                  .INDEX,
              params: { id: scope.row.id },
            })}},[_vm._v("Исключенные дилеры")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('ActionButtons',{attrs:{"add-trash":false,"edit-link":_vm.isStatusInactive(scope.row.status)
              ? _vm.getViewLinkByStatus(scope.row.id, scope.row.status)
              : '',"view-link":!_vm.isStatusInactive(scope.row.status)
              ? _vm.getViewLinkByStatus(scope.row.id, scope.row.status)
              : ''}})]}}])})],1),(_vm.totalPages > 1)?_c('el-pagination',{class:_vm.$style.pagination,attrs:{"layout":"prev, pager, next","page-size":_vm.limit,"total":_vm.total,"current-page":_vm.page,"background":""},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event},"current-change":_vm.getList}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }